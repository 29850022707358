
import { socketAutentica, socketDeAutentica } from './CodeeSocket';

export function getToken() {
    return localStorage.getItem('user_token');
}

export function setCredentials(loginUser) {
    loginUser.token = "Bearer " + loginUser.token;
    localStorage.setItem('user_id', loginUser.id);
    localStorage.setItem('user_token', loginUser.token);
    localStorage.setItem('user_name', loginUser.usuario.nome);
    socketAutentica(loginUser.token);

    return loginUser;
}

export function deCredential(history = false) {
    const userId = localStorage.getItem('user_id');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_name');

    socketDeAutentica(userId);

    if (history) {
        history.replace('/user/login')
    }
}